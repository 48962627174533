import appConfig from '@/config/app.config.json';
import moment from 'moment';

export const idCard = {
    get expirationDate() {
        const idCardExpirationDate = appConfig.find(config => config.key === 'ID_CARD_EXPIRATION_DATE_ALLOWANCE');
        if (moment(idCardExpirationDate.value).isValid()) {
            return moment(idCardExpirationDate.value).toDate();
        } else return moment(new Date()).startOf('day').toDate();
    },
}
